import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '../../../components';

import { FormattedMessage } from '../../../util/reactIntl';

import css from './SectionWhyUs.module.css';

const SectionWhyUs = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionWhyUs.title" />
      </div>

      <div className={css.columns}>
        <div className={css.box}>
          <h2 className={css.boxTitle}>
            <FormattedMessage id="SectionWhyUs.companyTitle" />
          </h2>
          <ul>
            <li>
              <FormattedMessage id="SectionWhyUs.companyReason1" />
            </li>
            <li>
              <FormattedMessage id="SectionWhyUs.companyReason2" />
            </li>
            <li>
              <FormattedMessage id="SectionWhyUs.companyReason3" />
            </li>
            <li>
              <FormattedMessage id="SectionWhyUs.companyReason4" />
            </li>
            <li>
              <FormattedMessage id="SectionWhyUs.companyReason5" />
            </li>
          </ul>
          <NamedLink name="SignupPage" className={css.boxButton}>
            <FormattedMessage id="SectionWhyUs.companySignup" />
          </NamedLink>
        </div>

        <div className={css.box}>
          <h2 className={css.boxTitle}>
            <FormattedMessage id="SectionWhyUs.recruiterTitle" />
          </h2>
          <ul>
            <li>
              <FormattedMessage id="SectionWhyUs.recruiterReason1" />
            </li>
            <li>
              <FormattedMessage id="SectionWhyUs.recruiterReason2" />
            </li>
            <li>
              <FormattedMessage id="SectionWhyUs.recruiterReason3" />
            </li>
            <li>
              <FormattedMessage id="SectionWhyUs.recruiterReason4" />
            </li>
            <li>
              <FormattedMessage id="SectionWhyUs.recruiterReason5" />
            </li>
          </ul>
          <NamedLink name="SignupPage" className={css.boxButton}>
            <FormattedMessage id="SectionWhyUs.recruiterSignup" />
          </NamedLink>
        </div>
      </div>

      <NamedLink name="AboutPage" className={css.faqLink}>
        <FormattedMessage id="SectionWhyUs.moreDetails" />
      </NamedLink>
    </div>
  );
};

SectionWhyUs.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionWhyUs.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionWhyUs;
